import { QueryControl, QueryControlType } from '@/model/query-control';
import { assignDefaultExtend } from '@/service/report';
import { QueryModel } from '@/model/query-model';
import { download } from '@/service/request';
import { ChartRingExtend } from '@/mixins/report-chart-base';
import { Moment } from 'moment';
import AccessControlIndexService from '../service/access-control-index';
import { percentFormat } from '@/filter';
import ParkingFacilityService from '../service/parking-facility';
import { PeriodTypeEnum, SubSystemType } from '@/model/enum';

export class AccessControlRtsTypeQueryModel extends QueryModel {
    @QueryControl({
        label: '区域',
        placeholder: '全部区域',
        type: QueryControlType.TREE_SELECT,
        optionsPromise: ParkingFacilityService.getLocationTree,
        optionsPromiseParam: SubSystemType.ACCESS_CONTROL,
        mode: 'multiple',
        selectChildren: false,
        span: 10,
        // defaultValue: '[0].value',
        required: false
    })
    locationIds: Array<string> = undefined;

    @QueryControl({
        label: '日期',
        type: QueryControlType.SELECT_DATE,
        showTime: false,
        span: 10,
        required: true
    })
    dateRange: Array<Moment> = JTL.CONSTANT.LAST_7_DAY();

    toService() {
        return {
            locationIds: this.locationIds,
            startTime: this.dateRange[0].toDate().getTime(),
            endTime: this.dateRange[1].clone().add(1, 'day').startOf('day').toDate().getTime(),
            intervalType: PeriodTypeEnum.DAY
        };
    }
}

export async function AccessControlRtsTypeService(model: AccessControlRtsTypeQueryModel) {
    const res = await AccessControlIndexService.getRtsTypeCount(model);
    const chartOptions = {
        title: '园内人数统计',
        data: res,
        settings: {
            type: 'ring'
        },
        extend: ChartRingExtend,
        tableRowKey: 'name',
        tableColumns: [
            {
                title: '项目',
                dataIndex: 'name'
            },
            {
                title: '数值',
                dataIndex: 'value'
            },
            {
                title: '占比',
                dataIndex: 'rate',
                customRender: (text, record, index) => {
                    return percentFormat(text);
                }
            }
        ]
    };
    return assignDefaultExtend(chartOptions);
}

export async function AccessControlRtsTypeExportService(queryModel: AccessControlRtsTypeQueryModel) {
    const url = `${BIBIZ_BASE_REQUEST_PATH}/accessControlSystem/rts/export/types`;
    const res = await download(url, queryModel?.toService(), 'post');
    return res;
}
